<template>
  <v-row dense no-gutters>
    <v-col>
      <v-list dense flat :min-height="$vuetify.breakpoint.height / 2">
        <v-row
          dense
          no-gutters
          v-for="(item, index) in listItems"
          :key="index"
        >
          <v-col>
            <v-list-item v-if="link"
              dense
              link
              :ripple="false"
              :to="`${item.id}`" append
            >
              <v-list-item-icon>
                <v-icon> {{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else
              dense
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon> {{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="1" class="text-right" v-show="hasDelete">
            <v-btn
              icon
              v-on:click.stop="confirmDelete.isDelete = true, confirmDelete.name = item.title, confirmDelete.id = item.id"
              color="danger"
            >
              <v-icon color="lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list>
    </v-col>
    <confirm-dialog v-model="confirmDelete.isDelete" type="delete" :title="confirmDelete.name" @confirm="$emit('delete', confirmDelete.id)"></confirm-dialog>

  </v-row>
    <!-- <empty-page v-if="listItems.length === 0"></empty-page> -->
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue'
// import EmptyPage from '../components/EmptyPage.vue'
export default {
  name: 'ListItem',
  components: {
    ConfirmDialog
  },
  props: {
    keyValue: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: [Array, Object],
      default () {
        return []
      },
      required: true
    },
    hasDelete: {
      type: Boolean,
      default: false,
      require: false
    },
    link: {
      type: Boolean,
      default: false,
      require: false
    },
    icon: {
      type: String,
      default: 'mdi-format-list-bulleted',
      require: false
    },
    title: {
      type: String,
      default: null,
      require: false
    },
    id: {
      type: String,
      default: null,
      require: false
    }
  },
  data: () => ({
    confirmDelete: {
      isDelete: false,
      name: '',
      id: ''
    }
  }),
  computed: {
    listItems: function () {
      const list = []
      this.items.forEach(item => {
        if (typeof item === 'string') {
          list.push({
            title: item,
            icon: this.icon
          })
        } else if (typeof item === 'object') {
          let aa = item
          this.title.split('.').forEach(tt => {
            aa = aa[tt]
          })
          // console.log(aa)
          if (aa) {
            list.push({
              id: item.id,
              title: aa,
              icon: item.icon || this.icon
            })
          }
        }
      })
      return list
    }
  },
  methods: {},
  created () {}
}
</script>
